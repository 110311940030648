<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Master from "@/apis/Master";
import Vue from "vue";
import {required} from "vuelidate/lib/validators";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "IMPORT",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "PRODUCT LIST",
          href: "/products"
        },
        {
          text: "IMPORT",
          active: true
        }
      ],
      productData: [],

      bulkImport:{
        csvFile:[]
      },
      paymentModeData: [],
      showmodal: false,
      showmodalPayment: false,
      submitted: false,
      submittedPayment: false,

      showRejectModel: false,
      submittedReject: false,
      loaderSpins: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.productData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.loaderSpins = false
    this.totalRows = this.productData.length;


  },
  validations: {
    bulkImport:{
      csvFile:{required}
    },
  },
  methods: {
    viewProducts(data){
      localStorage.setItem("productId",data);
    },


    onFileSelected(event){
      console.log(event.target.files)
      this.bulkImport.csvFile = event.target.files[0]
    },

    handleRejectedSubmit(){
      this.submittedReject = true;
      this.$v.$touch();
      if (this.$v.bulkImport.$invalid) {
        return;
      } else {
        this.loaderSpins = true
        this.paymentReject()
      }
    },

    rejectModal(){
      this.showRejectModel = true;
      this.loaderSpins = false
    },
    paymentReject(){
      const formData = new FormData()
      formData.append('file', this.bulkImport.csvFile)
      Master.importCSVFile(formData).then(res => {
        // console.log(res)

        Vue.swal({
          position: "center",
          icon: "success",
          title: ""+res.data.message+"",
          showConfirmButton: false,
          timer: 1500
        });
        // Master.productList({
        //   trash: 0
        // }).then((res) => {
        //   console.log(res)
        //
        //
        // })
        this.productData = res.data.error;
        console.log(res.data.error)
        // this.successMessage = res.data.message;
        this.showRejectModel = false
        this.submittedReject = false
        this.loaderSpins = false

      }).catch((err) => {
        console.log(err)
        this.loaderSpins = false
      });


    },

    hideRejectModel(){
      this.showRejectModel = false
      this.submittedReject = false
    },








      },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
};
</script>



<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform:uppercase !important;">
            <div class="btn-toolbar">
<!--              <router-link to="/product-add"> <a href="javascript:void(0);"  class="btn btn-primary mb-2 mr-1" >-->
<!--                <i class="mdi mdi-plus mr-2"></i> Add-->
<!--              </a></router-link>-->
<!--              <a href="javascript:void(0);" class="btn btn-outline-success mb-2 mr-1" @click="bulkActive">-->
<!--                <i class="mdi mdi-check mr-2"></i> Active-->
<!--              </a>-->
<!--              <a href="javascript:void(0);" class="btn btn-outline-danger mb-2 mr-1"  @click="bulkInactive">-->
<!--                <i class="mdi mdi-cancel mr-2"></i> In-Active-->
<!--              </a>-->
<!--              <a href="javascript:void(0);" class="btn btn-danger mb-2 mr-1" @click="bulkDelete">-->
<!--                <i class="mdi mdi-trash-can mr-2"></i> Delete-->
<!--              </a>-->

              <a href="javascript:void(0);" class="btn btn-success mb-2 mr-1" @click="rejectModal()" style="display: block; margin-left: auto">
                <i class="mdi mdi-upload mr-2"></i> Import
              </a>

            </div>
            <br/>

            <h4 class="card-title">Error List</h4>

              <ul v-for="(pro,index) in this.productData" :key="index">
                <li class="error" style="list-style-type: none">{{pro.errors[0]}} | Excel File Row : {{pro.row }} column : {{pro.attribute}}</li>
              </ul>

            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                <tr>
<!--                  <th>#</th>-->
                  <th>Brand</th>
                  <th>Product Title</th>
                  <th>SKU</th>
                  <th>Product Type</th>
                  <th>Descriptions</th>
                  <th>Specifications</th>
                  <th>Filters</th>
                  <th>Price</th>
                  <th>Offer Price</th>
                  <th>Cost Per Item</th>
                  <th>Dimensions</th>
                  <th>Size</th>
                  <th>Color</th>
                  <th>Stock</th>
                  <th>Parent</th>
                  <th>Main Image URL</th>
                  <th>Image URL 1</th>
                  <th>Image URL 2</th>
                  <th>Image URL 3</th>
                  <th>Image URL 4</th>
                  <th>Image URL 5</th>
                  <th>Image URL 6</th>
                  <th>Image URL 7</th>
                  <th>CAD Block Image URL</th>
                  <th>3D URL</th>
                  <th>Logistics Information</th>
                  <th>SEO Title</th>
                  <th>SEO Description</th>
                  <th>SEO Keywords</th>
                  <th>HS Code</th>
                  <th>CAD Block DWG</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(pro,index) in this.productData" :key="index">
<!--                  <th scope="row">{{ pro.values[0] }}</th>-->
                  <td>{{ pro.values[1] }}</td>
                  <td>{{ pro.values[2] }}</td>
                  <td><p>{{ pro.values[3]}}</p></td>
                  <td>{{ pro.values[4] }}</td>
                  <td><p :title="pro.values[5]"  v-if="pro.values[5]" >{{ pro.values[5].slice(0, 20).trim() }}</p></td>
                  <td>{{ pro.values[6] }}</td>
                  <td>{{ pro.values[7] }}</td>
                  <td>{{ pro.values[8] }}</td>
                  <td>{{ pro.values[9] }}</td>
                  <td>{{ pro.values[10] }}</td>
                  <td>{{ pro.values[11] }}</td>
                  <td>{{ pro.values[12] }}</td>
                  <td>{{ pro.values[13] }}</td>
                  <td>{{ pro.values[14] }}</td>
                  <td>{{ pro.values[16] }}</td>
                  <td>{{ pro.values[17] }}</td>
                  <td>{{ pro.values[18] }}</td>
                  <td>{{ pro.values[19] }}</td>
                  <td>{{ pro.values[20] }}</td>
                  <td>{{ pro.values[21] }}</td>
                  <td>{{ pro.values[22] }}</td>
                  <td>{{ pro.values[23] }}</td>
                  <td>{{ pro.values[24] }}</td>
                  <td>{{ pro.values[27] }}</td>
                  <td>{{ pro.values[28] }}</td>
                  <td>{{ pro.values[29] }}</td>
                  <td>{{ pro.values[30] }}</td>
                  <td>{{ pro.values[31] }}</td>
                  <td>{{ pro.values[32] }}</td>
                  <td>{{ pro.values[33] }}</td>
                  <td>{{ pro.values[39] }}</td>
                  <td>{{ pro.values[38] }}</td>

                </tr>


                </tbody>
              </table>
            </div>


          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="showRejectModel"
        title="IMPORT BULK PRODUCTS"
        title-class="text-dark font-18"
        hide-footer
        hide-header-close
        @close="hideRejectModel"
    >

      <form @submit.prevent="handleRejectedSubmit" enctype="multipart/form-data">
        <div class="form-group">
          <label for="name">EXCEL FILE *</label>
          <input type="file" accept=".xlsx, text/xlsx" class="form-control-file" @change="onFileSelected"  ref="files" multiple="multiple" :class="{ 'is-invalid': submittedReject && $v.bulkImport.csvFile.$error }">
          <div
              v-if="submittedReject && !$v.bulkImport.csvFile.required"
              class="invalid-feedback"
          >FILE IS REQUIRED</div>
        </div>


        <div class="text-right" >

          <div  v-if="loaderSpins">
            <b-spinner class="m-2" variant="dark" role="status"></b-spinner>
          </div>
          <div v-else>
          <button type="submit" class="btn btn-success">SAVE</button>
          <b-button class="ml-1" variant="danger" @click="hideRejectModel">CANCEL</b-button>
          </div>

        </div>

      </form>
      <br/>

    </b-modal>

  </Layout>
</template>
<style>
.text-breaker{
  width: 75%;
  word-break: break-word;
  display: block;
  text-align: left;
  white-space: pre-wrap;
  line-height: 1.25em;
}
</style>